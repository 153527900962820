import React, { Component } from 'react';

import Gallery from 'react-photo-gallery';
import IndexNavbar from "../../Navbars/IndexNavbar";
import Footer from "../../Footer/Footer";
import {Col, Container, Row} from "reactstrap";
import Address from "../Address/Address";

const av_1 = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/automotriz%2Fav_1.jpg?alt=media&token=913765c3-fd68-4829-9bff-49a6b2507326";
const av_2 = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/automotriz%2Fav_2.jpg?alt=media&token=7229778d-d368-41c9-87b8-0a67ae70614d";
const av_3 = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/automotriz%2Fav_3.jpg?alt=media&token=f2c274e1-24ba-4a39-be11-24037ed06073";
const av_4 = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/automotriz%2Fav_4.jpg?alt=media&token=0787c82d-e6f1-4c26-8b59-0f0b58c7f732";
const av_5 = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/automotriz%2Fav_5.jpg?alt=media&token=db05ac1c-7a74-4ab4-b916-6d02524e0fd3";
const av_6 = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/automotriz%2Fav_6.jpg?alt=media&token=59d362e7-0fea-4208-bd06-b247a1a0ddc9";
const av_8 = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/autotanque%2Frt_3.jpg?alt=media&token=abbebf03-f241-4f77-b707-17107cca6337";
const av_7 = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/automotriz%2Fav_9.jpg?alt=media&token=eb7c6bab-0e5a-4de6-baa3-3f1b2087e9ef";


class Accessories extends Component {
    render() {
         const photos = [
            {
                src: av_1,
                width: 4,
                height: 3,
                id:0
            },
            {
                src: av_2,
                width: 1,
                height: 1,
                id:1
            },
            {
                src: av_3,
                width: 3,
                height: 4,
                id:2
            },
            {
                src: av_4,
                width: 3,
                height: 4,
                id:3
            },
            {
                src: av_5,
                width: 3,
                height: 4,
                id:4
            },
            {
                src: av_6,
                width: 4,
                height: 3,
                id:5
            },
            {
                src: av_8,
                width: 4,
                height: 3,
                id:7
            },
            {
                src: av_1,
                width: 4,
                height: 3,
                id:8
            },
            {
                src: av_2,
                width: 3,
                height: 4,
                id:9
            },
            {
                src: av_3,
                width: 4,
                height: 3,
                id:10
            },
            {
                src: av_4,
                width: 4,
                height: 3,
                id:11
            },
            {
                src: av_5,
                width: 4,
                height: 3,
                id:12
            },
            {
                src: av_7,
                width: 4,
                height: 3,
                id:13
            },
            {
                src: av_6,
                width: 4,
                height: 3,
                id:15
            }
        ];

        return (
            <React.Fragment>
                <IndexNavbar />
                <br /><br /><br /><br /><br /><br />
                <div className="wrapper">
                       <section className="section section-lg section-safe">
                           <Container>
                               <Row className="row-grid justify-content-between">
                                   <Col md="6">
                                       <div className="px-md-5">
                                           <h3>Accesorios.</h3>
                                           <p>
                                               Contamos con una gran variedad de conectores, terminales, cables, sellos, sujetadores de cable, etc.
                                           </p>

                                       </div>
                                   </Col>
                                   <Col md="5">
                                   </Col>
                               </Row>
                           </Container>
                           <br></br>
                           <br></br>
                           <Container>
                                <Row className="justify-content-between align-items-center">
                                    <Gallery photos={photos} />
                                </Row>
                            </Container>
                           <br></br><br></br>
                             <Address />
                       </section>
                </div>
                <Footer />

            </React.Fragment>
        );
    }
}
export default Accessories;
