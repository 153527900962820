import React from "react";
import classnames from "classnames";

import PerfectScrollbar from "perfect-scrollbar";

import {
    Card,
    CardHeader,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    Table,
    TabContent,
    TabPane,
    Container,
    Row,
    Col
} from "reactstrap";

import Footer from "./../../../Footer/Footer";
import IndexNavbar from "../../../Navbars/IndexNavbar";
import TabsCategories from "./ArnesComponents/TabsCategories";
import Address from '../../Address/Address';
import CarouselCategories from '../../carousel/CarouselCategories';

const a1 = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/tanque_card.jpg?alt=media&token=623c0f59-da92-43d1-a42a-d9628e18c569";
const rt_1 = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/autotanque%2Frt_1.jpg?alt=media&token=fa2f5d4e-eeb1-4ef2-82e6-602c4bb6d01c";
const rt_2 = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/autotanque%2Frt_2.jpg?alt=media&token=b9e07a91-757f-45cd-97db-18734b50739e";
const rt_3 = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/autotanque%2Frt_3.jpg?alt=media&token=abbebf03-f241-4f77-b707-17107cca6337";
const rt_4 = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/autotanque%2Frt_4.jpg?alt=media&token=2abdb637-b74f-463f-b8d2-5a152382bb3a";
const rt_5 = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/autotanque%2Frt_5.jpg?alt=media&token=7ce4eccd-77fc-44bf-8269-0f1317250c14";
const rt_6 = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/autotanque%2Frt_6.jpg?alt=media&token=c9376035-1fea-4f8a-a6f3-c31fb248f4ba";


const items = [
    {
        src: rt_1,
        altText: "Arnés Tanque",
        caption: '',
        text: 'Cable de poder ABS.'
    },
    {
        src: rt_2,
        altText: "Arnés Tanque",
        caption: '',
        text: 'Extensión para plafones.'
    },
    {
        src: rt_3,
        altText: "Arnés Tanque",
        caption: '',
        text: 'Extensión para plafones.'
    },
    {
        src: rt_4,
        altText: "Arnés Tanque",
        caption: '',
        text: 'SAE J2394.'
    },
    {
        src: rt_5,
        altText: "Arnés Tanque",
        caption: '',
        text: 'Punto de conexión de plafón lateral.'
    },{
        src: rt_6,
        altText: "Arnés Tanque",
        caption: '',
        text: 'Trailer cable SAE J2394'
    }
];

let ps = null;

class ArnesP1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabs: 1,
            iconTabs: 1,
            textTabs: 4,
            formModal: false
        };
    }

    componentDidMount() {
        if (navigator.platform.indexOf("Win") > -1) {
            document.documentElement.className += " perfect-scrollbar-on";
            document.documentElement.classList.remove("perfect-scrollbar-off");
            let tables = document.querySelectorAll(".table-responsive");
            for (let i = 0; i < tables.length; i++) {
                ps = new PerfectScrollbar(tables[i]);
            }
        }
        document.body.classList.toggle("arnes-7-vias");
    }
    componentWillUnmount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
            document.documentElement.className += " perfect-scrollbar-off";
            document.documentElement.classList.remove("perfect-scrollbar-on");
        }
        document.body.classList.toggle("arnes-7-vias");
    }

    toggleTabs = (e, stateName, index) => {
        e.preventDefault();
        this.setState({
            [stateName]: index
        });
    };

    render() {
        const arnes1 = 'arnes-type-1';
        return (
            <div>
                <IndexNavbar />
                <br /><br /><br /><br /><br />
                <Container>
                    <div className="content-center">
                        <div className="section">
                            <Row>
                                <Col lg="6" md="6">
                                    <h1 className="profile-title text-left">ARNÉS CABLE 7 VÍAS</h1>
                                    <h3 className="profile-title">Diseño y fabricación a medida.</h3>
                                    <p className="profile-description">
                                        Fabricamos var arneses para remolque y autotanques que cumplen con la norma SAE J2394. Con las versatilidad de diferentes distancias para conexión de plafones traseros y laterales, así como direccionales, cable ABS y testigo de ABS.

                                    </p><br />
                                    <TabsCategories arnes1={arnes1} />

                                </Col>
                                <Col className="ml-auto mr-auto padd" lg="4" md="6">
                                    <Card className="card-coin card-plain">
                                        <CardHeader>
                                            <img
                                                alt="..."
                                                className="img-center img-fluid"
                                                src={a1}
                                            />
                                            <h4 className="title content-center-card">ARNÉS CABLE 7 VÍAS</h4>
                                        </CardHeader>
                                        <CardBody>
                                            <Nav
                                                className="nav-tabs-primary justify-content-center"
                                                tabs
                                            >
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({
                                                            active: this.state.tabs === 1
                                                        })}
                                                        onClick={e => this.toggleTabs(e, "tabs", 1)}>
                                                        Carácteristicas
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({
                                                            active: this.state.tabs === 2
                                                        })}
                                                        onClick={e => this.toggleTabs(e, "tabs", 2)}>
                                                        Medidas
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                            <TabContent
                                                className="tab-subcategories"
                                                activeTab={"tab" + this.state.tabs} >
                                                <TabPane tabId="tab2">
                                                    <Table className="tablesorter" responsive>
                                                        <p>Desarrollamos arneses con medidas especiales.</p>
                                                    </Table>
                                                </TabPane>
                                                <TabPane tabId="tab1">
                                                    <Table className="tablesorter" responsive>
                                                        <thead>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td>Cumple con la norma SAE J2394.</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Fácil y rápida instalación.</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Adaptable a cualquier tipo de plafon y/o direccionales.</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Versatilidad de diseño. (Distancias, número de plafones, etc.)</td>
                                                        </tr>
                                                        </tbody>
                                                    </Table>
                                                </TabPane>
                                            </TabContent>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="section">
                        <Container>
                            <Row className="justify-content-between">
                                <Col md="6">
                                    <Row className="justify-content-between align-items-center">
                                        <CarouselCategories items={items}/>
                                    </Row>
                                </Col>
                                <Col md="5">
                                    <h1 className="profile-title text-left">Proyectos</h1>
                                    <p className="profile-description text-left">
                                        Fabricamos arnés para remolque y autotanque como traje a la medida.</p>
                                    <p>Somos capaces de crear un diseño o idea y desarrollarlo en prototipos y desde allí a un producto completamente funcional.</p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Address />
                </Container>
                <Footer />
            </div>
        );
    }
}

export default ArnesP1;
