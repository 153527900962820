
import React from "react";
import classnames from "classnames";
import PerfectScrollbar from "perfect-scrollbar";
import {
    Card,
    CardHeader,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    Table,
    TabContent,
    TabPane,
    Container,
    Row,
    Col
} from "reactstrap";

import Footer from "./../../../Footer/Footer";
import IndexNavbar from "../../../Navbars/IndexNavbar";
import ModalForm from "./ArnesComponents/ModalForm";
import TabsCategories from "./ArnesComponents/TabsCategories";
import Address from "../../Address/Address";
import CarouselCategories from "../../carousel/CarouselCategories";

const pe_0_card = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/pe_0_card.jpg?alt=media&token=0eb2d6c1-4753-4045-b1c5-97397c8b9d9a";
const pe_1 = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/portaequipaje%2Fpe_1.jpg?alt=media&token=13303148-5b98-4d4c-bbfd-231ed4f336ba";
const pe_2 = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/portaequipaje%2Fpe_2.jpg?alt=media&token=194ae4f0-da8b-4b76-ad50-57d9b13b3b07";
const pe_3 = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/portaequipaje%2Fpe_3.jpg?alt=media&token=83de3b84-3612-4268-958e-608625347c75";
const pe_4 = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/portaequipaje%2Fpe_4.jpg?alt=media&token=f863f0b1-5906-4b21-9534-dbc5c445cd5a";
const pe_6 = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/portaequipaje%2Fpe_6.jpg?alt=media&token=4c0a4683-f6e0-417c-96ec-b94239aeb49d";
const pe_7 = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/portaequipaje%2Fpe_7.jpg?alt=media&token=47e13aa9-e56e-4ffc-8f05-d3e2552cf4ca";
const pe_8 = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/portaequipaje%2Fpe_8.jpg?alt=media&token=e9019af8-c5ed-4a38-bb59-17ef7a7771ac";
const av_7 = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/sundry%2Fav_8.jpg?alt=media&token=209ca50a-b8e0-44ad-848b-ea0539f79526";


const items = [{
        src: pe_1,
        altText: "PortaEquipaje",
        caption: "",
        text: 'Arnés de bocina.'
    },
    {
        src: pe_2,
        altText: "PortaEquipaje",
        caption: "",
        text: 'Arnés de led.'
    },
    {
        src: pe_3,
        altText: "PortaEquipaje",
        caption: "",
        text: 'Arnés multiset.'
    },
    {
        src: pe_4,
        altText: "PortaEquipaje",
        caption: "",
        text: 'Jumper led.'
    },
    {
        src: pe_6,
        altText: "PortaEquipaje",
        caption: "",
        text: 'Arnés para bocinas.'
    },
    {
        src: pe_7,
        altText: "PortaEquipaje",
        caption: "",
        text:'Arnés multiset.'
    },
    {
        src: pe_8,
        altText: "PortaEquipaje",
        caption: "",
        text:'Arnés multiset con bases.'
    },{
      src: av_7,
      altText: "PortaEquipaje",
      caption: "",
      text:'Arnés USB.'
    }

];

let ps = null;

class ArnesP1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabs: 1
        };
    }
    componentDidMount() {
        if (navigator.platform.indexOf("Win") > -1) {
            document.documentElement.className += " perfect-scrollbar-on";
            document.documentElement.classList.remove("perfect-scrollbar-off");
            let tables = document.querySelectorAll(".table-responsive");
            for (let i = 0; i < tables.length; i++) {
                ps = new PerfectScrollbar(tables[i]);
            }
        }
        document.body.classList.toggle("arnes-autobus");
    }
    componentWillUnmount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
            document.documentElement.className += " perfect-scrollbar-off";
            document.documentElement.classList.remove("perfect-scrollbar-on");
        }
        document.body.classList.toggle("arnes-autobus");
    }
    toggleTabs = (e, stateName, index) => {
        e.preventDefault();
        this.setState({
            [stateName]: index
        });
    };
    render() {
        const arnesAutobus = 'arnes_autobus';
        return (
            <div>
            <IndexNavbar />
            <br /><br /><br /><br /><br />
                        <Container>
                            <div className="content-center">
                                <div className="section">
                                <Row>
                                    <Col lg="6" md="6">
                                        <h1 className="profile-title text-left">ARNÉS PARA AUTOBÚS</h1>
                                        <h3 className="profile-title">Diseño y fabricación a medida.</h3>
                                        <p className="profile-description">

                                            Nos dedicamos a fabricar a todo tipo de arneses para la industria de autobuses: arnés para portaequipajes y cargadores USB.
                                        </p><br />
                                        <TabsCategories arnesAutobus={arnesAutobus} />
                                        <ModalForm />
                                    </Col>
                                    <Col className="ml-auto mr-auto padd" lg="4" md="6">
                                        <Card className="card-coin card-plain">
                                        <CardHeader>
                                    <img
                                        alt="..."
                                        className="img-center img-fluid"
                                        src={pe_0_card}
                                    />
                                    <h4 className="title content-center-card">ARNÉS PORTAEQUIPAJE</h4>
                                </CardHeader>

                                            <CardBody>
                                                <Nav
                                                    className="nav-tabs-primary justify-content-center"
                                                    tabs
                                                >
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({
                                                                active: this.state.tabs === 1
                                                            })}
                                                            onClick={e => this.toggleTabs(e, "tabs", 1)}

                                                        >
                                                            Carácteristicas
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({
                                                                active: this.state.tabs === 2
                                                            })}
                                                            onClick={e => this.toggleTabs(e, "tabs", 2)}
                                                            href=""
                                                        >
                                                            Medidas
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>
                                                <TabContent
                                                    className="tab-subcategories"
                                                    activeTab={"tab" + this.state.tabs}
                                                >
                                                    <TabPane tabId="tab2">
                                                        <Table className="tablesorter" responsive>
                                                          <p>Desarrollamos arneses con medidas especiales.</p>
                                                        </Table>
                                                    </TabPane>
                                                    <TabPane tabId="tab1">
                                                        <Table className="tablesorter" responsive>
                                                            <thead>
                                                            </thead>
                                                            <tbody>
                                                            <tr>
                                                                <td>Fácil y rápida instalación.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Versatilidad de diseño. (Diferentes longuitudes, conectores, terminales, etc.)</td>
                                                            </tr>
                                                            </tbody>
                                                        </Table>
                                                    </TabPane>
                                                </TabContent>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                </div>
                            </div>
                            <div className="section">
                                <Container>
                                    <Row className="justify-content-between">
                                        <Col md="6">
                                            <Row className="justify-content-between align-items-center">
                                                <CarouselCategories items={items}/>
                                            </Row>
                                        </Col>
                                        <Col md="5">
                                            <h1 className="profile-title text-left">Proyectos</h1>
                                            <p className="profile-description text-left">
                                                Fabricamos arnés para Autobuses como traje a la medida.</p>
                                            <p>Somos capaces de crear un diseño o idea y desarrollarlo en prototipos y desde allí a un producto completamente funcional.</p>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                            <Address />
                        </Container>
                <Footer />

            </div>
        );
    }
}

export default ArnesP1;
