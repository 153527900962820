import React from "react";
import { Link } from 'react-router-dom';
import logo from './../../assets/img/LOGOAB.png';
import {
    Button,
    Collapse,
    NavbarBrand,
    Navbar,
    NavItem,
    Nav,
    Container,
    Row,
    Col,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown
} from "reactstrap";

class ComponentsNavbar extends React.Component {
    constructor() {
        super();
        this.state = {
            collapseOpen: false,
            color: "bg-white",
            subMenuNavbar:[{ id:1, to:"/arnes-7-vias", icon:"tim-icons icon-vector", text:"Arnés 7 vías"},
                     { id:2, to:"/arnes-autobus", icon:"tim-icons icon-delivery-fast", text:"Arnés Autobús"},
                     { id:3, to:"/arnes-automotriz", icon:"tim-icons icon-bus-front-12", text:"Arnés Automotriz"}]
        };
    }
    componentDidMount() {
        window.addEventListener("scroll", this.changeColor);
        window.scrollTo(0, 0)

    }
    componentWillUnmount() {
      window.scrollTo(0, 0)
        window.removeEventListener("scroll", this.changeColor);
    }
    changeColor = () => {
        if (
            document.documentElement.scrollTop > 99 ||
            document.body.scrollTop > 99
        ) {
            this.setState({
                color: "bg-white"
            });
        } else if (
            document.documentElement.scrollTop < 100 ||
            document.body.scrollTop < 100
        ) {
            this.setState({
                color: "bg-white"
            });
        }
    };
    toggleCollapse = () => {
        document.documentElement.classList.toggle("nav-open");
        this.setState({
            collapseOpen: !this.state.collapseOpen
        });
    };
    onCollapseExiting = () => {
        this.setState({
            collapseOut: "collapsing-out"
        });
    };
    onCollapseExited = () => {
        this.setState({
            collapseOut: ""
        });
    };

    render() {
        return (
            <Navbar
                className={"changeHeight fixed-top " + this.state.color}
                color-on-scroll="100"
                expand="lg">
                <Container>
                    <div className="navbar-translate">
                        <NavbarBrand
                            data-placement="bottom"
                            to="/ab"
                            tag={Link}>
                            <div className="logo">
                                <img alt="..." src={logo}></img>
                            </div>
                        </NavbarBrand>
                        <button
                            aria-expanded={this.state.collapseOpen}
                            className="navbar-toggler navbar-toggler"
                            onClick={this.toggleCollapse}
                        >
                            <span className="navbar-toggler-bar bar1" />
                            <span className="navbar-toggler-bar bar2" />
                            <span className="navbar-toggler-bar bar3" />
                        </button>
                    </div>
                    <Collapse
                        className={"justify-content-end " + this.state.collapseOut}
                        navbar
                        isOpen={this.state.collapseOpen}
                        onExiting={this.onCollapseExiting}
                        onExited={this.onCollapseExited}
                    >
                        <div className="navbar-collapse-header">
                            <Row>

                                <Col className="collapse-brand" xs="6">
                                    <div className="logo">
                                        <img alt="..." src={logo}></img>
                                    </div>
                                </Col>
                                <Col className="collapse-close text-right" xs="6">
                                    <button
                                        aria-expanded={this.state.collapseOpen}
                                        className="navbar-toggler"
                                        onClick={this.toggleCollapse}
                                    >
                                        <i className="tim-icons icon-simple-remove" />
                                    </button>
                                </Col>
                            </Row>
                        </div>

                        <Nav navbar>
                        <Row className="justify-content-center">
                        <Col lg="12">
                            <Row className="row-grid justify-content-center">
                                <Col lg="6">
                                    <div className="info">
                                        <div className="icon icon-success">
                                            <i className="tim-icons icon-square-pin customIcon"/>
                                        </div>
                                        <span className="justify-contact customSize">
                                           Vicente Guerrero #37 San Miguel Chalma, Tlanepantla de Baz, Estado de México.
                                            CP. 54140
                                        </span>
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div className="info">
                                        <div className="icon icon-success">
                                            <i className="tim-icons icon-single-02 customIcon" />
                                        </div>
                                        <span className="customSize">
                                          <b><a className="colorCustomPhone" href="tel:+55 58 93 92 27" rel="nofollow"> (55) 58 93 92 27 </a></b><br/>
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                        <NavItem>
                            <Button to="/ab" tag={Link} className="btn-custom btn-link">
                                Inicio
                            </Button>

                        </NavItem>
                                <UncontrolledDropdown nav>
                                    <DropdownToggle
                                    caret
                                    color="default"
                                    data-toggle="dropdown"
                                    href="#"
                                    nav
                                    onClick={e => e.preventDefault()} >
                                    <i className="fa fa-cogs d-lg-none d-xl-none btn-custom" />
                                    Productos
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-with-icons " component="li">
                                        {this.state.subMenuNavbar.map((item, index) => (
                                            <DropdownItem tag={Link} to={item.to} key={index}>
                                                <i className={item.icon} />
                                                {item.text}
                                            </DropdownItem>
                                            ))}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            <NavItem>
                                <Button to="/accesorios" tag={Link} className="btn-custom btn-link">
                                    Accesorios
                                </Button>
                            </NavItem>
                            <NavItem>
                                <Button to="/acerca-de-nosotros" tag={Link} className="btn-custom btn-link" >
                                    Acerca de nosotros
                                </Button>

                            </NavItem>
                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>
        );
    }
}

export default ComponentsNavbar;
