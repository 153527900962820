import React from 'react';

import {
    Card,
    CardBody,
    CardTitle,
    Container,
    Row,
    Col
} from "reactstrap";

export default class Products extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            message : "",
            btnList: [ {id:0, actived: false, headerTitle: "PRODUCCIÓN",title: "Producción", subtitle: "Arneses", icon:"tim-icons icon-settings text-warning", msg:"Producimos bajo estandarés de cálidad que permitén asegurar la cálidad de los productos y repetibilidad.\n"},
                {id:1, actived: false, headerTitle: "MODIFICACION", title: "Modificación", subtitle: "Arneses", icon:"tim-icons icon-components text-white", msg:"Ofrecemos el servicio de modificación y ajuste de arneses automotrices, dando funcionalidad adecuada para la situación que nuestros clientes requieran."},
                {id:2, actived: false, headerTitle: "VENTAS", title:"Ventas", subtitle: "Arneses", icon:"tim-icons icon-coins text-info", msg:"Ofrecemos una gran variedad de arneses que se ajustan a las necesidades de nuestros clientes."},
                {id:3, actived: false, headerTitle: "Diseño", title:"Diseño", subtitle: "Arneses", icon:"tim-icons icon-laptop text-success", msg:"Diseñamos el arnés que cumpla con los objetivos y requerimientos para satisfacer a nuestro cliente."}]
        };
    }

    changeInfo = i => {
        this.setState(state => {
            const list = state.btnList.map((item, index) => {
                item.actived = false;
                if(index === i){
                    state.message = item.msg
                    state.title = item.headerTitle
                    state.icon = item.icon
                    item.actived = true
                }
                return list;
            });
            return list;
        });
    }

    render() {
        return (
          <div className="section padd-section">
            <Container>
                <Row className="row-grid justify-content-between">
                    <Col className="mt-lg-5" md="5">
                        <Row>
                            {this.state.btnList.map((item, index) => (
                                <Col className="px-2 py-2" lg="6" sm="12" key={item.id} onClick={() => this.changeInfo(index)}>
                                    <Card className="card-stats" color={(!item.actived) ? '#1f2251' : 'custom'} >
                                        <CardBody >
                                            <Row>
                                                <Col md="4" xs="5">
                                                    <div className="icon-big text-center icon-warning">
                                                        <i className={item.icon} />
                                                    </div>
                                                </Col>
                                                <Col md="8" xs="7">
                                                    <div className="numbers">
                                                        <CardTitle tag="p">{item.title}</CardTitle>
                                                        <p />
                                                        <p className="card-category">{item.subtitle}</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                    <Col md="6">
                        <div className="info">
                            { this.state.title === ''
                                ?    <div className="icon icon-primary"><i className="tim-icons icon-settings text-warning"/></div>
                                :  <div className="icon icon-primary"><i className={this.state.icon} /> </div>
                            }
                            { this.state.title === ''
                                ?   <h1 className="info-title">PRODUCCIÓN</h1>
                                : <h1 className="info-title">{this.state.title}</h1>
                            }

                            { this.state.title === ''
                                ? <p>Producimos bajo estandarés de cálidad que permitén asegurar la cálidad de los productos y repetibilidad.</p>
                                : <p> {this.state.message}</p>
                            }
                         </div>
                    </Col>
                </Row>
            </Container>
            </div>
        );
    }
}
