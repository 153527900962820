import { lazy, Suspense} from "react";
import React from 'react';
import Loader from 'react-loader-spinner'

import IndexNavbar from "./../Navbars/IndexNavbar.js";
import Footer from "./../Footer/Footer.js";

import Products from "./Products/Products";
import Contact from './Contact/Contact';
import Appliances from './Appliances/Appliances';


import {
    Container,
} from "reactstrap";
import ModalForm from "./Routes/Products/ArnesComponents/ModalForm";
const Card = lazy(() => import('./CardsProducts/CardsProducts'));
const PageHeader = lazy(() => import("./../PageHeader/PageHeader.js"));

class Index extends React.Component {

    componentDidMount() {
        document.body.classList.toggle("index-page");
    }
    componentWillUnmount() {
        document.body.classList.toggle("index-page");
    }

    render() {
        return (
            <React.Fragment>
                <IndexNavbar />
                <div className="wrapper">
                    <Suspense fallback={<Loader className="wrapper-spinner"
                        type="Oval"
                        color="#f44336"
                        height={200}
                        width={200}
                        timeout={4000} //3 secs
                    />}>
                        <PageHeader />
                        <Card />
                    </Suspense>
                    {/*<CardsProducts />*/}
                    <Appliances />
                        <div className="main">
                                <Container>
                                    <Products />
                                    {/* <Carousel /> */}
                                    <Contact />
                                    <ModalForm />
                                </Container>
                        </div>
                    <Footer />
                </div>
            </React.Fragment>
        );
    }
}

export default Index;
