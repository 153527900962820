import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "./assets/css/app.scss";
import "./assets/css/index.css";
import "./assets/css/nucleo-icons.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"


import Index from "./components/views/index";
import Arnes from "./components/views/Routes/Products/ArnesP1";
import ArnesAutobus from "./components/views/Routes/Products/ArnesP2";
import ArnesAutomotriz from "./components/views/Routes/Products/ArnesP3";
import About from "./components/views/Routes/About/About";
import Accessories from "./components/views/Accessories/Accessories";


ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <Route path="/ab" render={props => <Index {...props} />} />
            <Route
                path="/arnes-7-vias"
                render={props => <Arnes {...props} />}
            />
            <Route
                path="/arnes-autobus"
                render={props => <ArnesAutobus {...props} />}
            />
            <Route
                path="/arnes-automotriz"
                render={props => <ArnesAutomotriz {...props} />}
            />
            <Route
                path="/acerca-de-nosotros"
                render={props => <About  {...props} />}
            />
            <Route
                path="/accesorios"
                render={props => <Accessories  {...props} />}
            />

            <Redirect from="/" to="/ab" />
            <Redirect from="/acerca-de-nosotros" to="/acerca-de-nosotros" />
        </Switch>
    </BrowserRouter>,
    document.getElementById("root")
);
