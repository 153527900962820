
import React from "react";
import classnames from "classnames";

import PerfectScrollbar from "perfect-scrollbar";
import {
    Card,
    CardHeader,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    Table,
    TabContent,
    TabPane,
    Container,
    Row,
    Col
} from "reactstrap";


import Footer from "./../../../Footer/Footer";
import IndexNavbar from "../../../Navbars/IndexNavbar";
import ModalForm from "./ArnesComponents/ModalForm";
import TabsCategories from "./ArnesComponents/TabsCategories";
import Address from "../../Address/Address";
import CarouselCategories from "../../carousel/CarouselCategories";

const av_0_card = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/automotriz%2Fav_0_card.jpg?alt=media&token=3e9e762b-1b7d-4565-9601-919f3d2a8764";
const av_1 = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/automotriz%2Fav_1.jpg?alt=media&token=913765c3-fd68-4829-9bff-49a6b2507326";
const av_2 = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/automotriz%2Fav_2.jpg?alt=media&token=7229778d-d368-41c9-87b8-0a67ae70614d";
const av_3 = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/automotriz%2Fav_3.jpg?alt=media&token=f2c274e1-24ba-4a39-be11-24037ed06073";
const av_4 = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/automotriz%2Fav_4.jpg?alt=media&token=0787c82d-e6f1-4c26-8b59-0f0b58c7f732";
const av_5 = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/automotriz%2Fav_5.jpg?alt=media&token=db05ac1c-7a74-4ab4-b916-6d02524e0fd3";
const av_6 = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/automotriz%2Fav_6.jpg?alt=media&token=59d362e7-0fea-4208-bd06-b247a1a0ddc9";
const av_7 = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/automotriz%2Fav_9.jpg?alt=media&token=eb7c6bab-0e5a-4de6-baa3-3f1b2087e9ef";
const av_8 = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/automotriz%2Fav_8.jpg?alt=media&token=9632ece5-a728-4cd7-ba5c-6b651ef676bb";


const items = [{
    src: av_1,
    altText: "automotriz",
    caption: "",
    text:'Jumper led.'
},
{
    src: av_2,
    altText: "automotriz",
    caption: "",
    text:'Jumper led.'
},
{
    src: av_3,
    altText: "automotriz",
    caption: "",
    text:'Conexiones multiples.'
},
{
    src: av_4,
    altText: "automotriz",
    caption: "",
    text:'Jumper led.'
},
{
    src: av_5,
    altText: "automotriz",
    caption: "",
    text:'Trailer cable SAEJ 2394'
},
{
    src: av_6,
    altText: "automotriz",
    caption: "",
    text:'Arnés cargador USB'
},
{
    src: av_7,
    altText: "automotriz",
    caption: "",
    text:'Toma corriente con cargador USB.'
},
{
    src: av_8,
    altText: "automotriz",
    caption: "",
    text:'Arnés USB'
},

];

let ps = null;

class ArnesP3 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabs: 1
        };
    }
    componentDidMount() {
        if (navigator.platform.indexOf("Win") > -1) {
            document.documentElement.className += " perfect-scrollbar-on";
            document.documentElement.classList.remove("perfect-scrollbar-off");
            let tables = document.querySelectorAll(".table-responsive");
            for (let i = 0; i < tables.length; i++) {
                ps = new PerfectScrollbar(tables[i]);
            }
        }
        document.body.classList.toggle("arnes-automotriz");
    }
    componentWillUnmount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
            document.documentElement.className += " perfect-scrollbar-off";
            document.documentElement.classList.remove("perfect-scrollbar-on");
        }
        document.body.classList.toggle("arnes-automotriz");
    }
    toggleTabs = (e, stateName, index) => {
        e.preventDefault();
        this.setState({
            [stateName]: index
        });
    };
    render() {
        const arnesAutomotriz = 'arnes_automotriz';
        return (
            <div>
            <IndexNavbar />
            <br /><br /><br /><br /><br />
                        <Container>
                            <div className="content-center">
                                <div className="section">
                                <Row>
                                    <Col lg="6" md="6">
                                        <h1 className="profile-title text-left">ARNÉS AUTOMOTRIZ</h1>
                                        <h3 className="profile-title">Diseño y fabricación a medida.</h3>
                                        <p className="profile-description">
                                            Nos dedicamos a fabricar a todo tipo de arneses para la industria automotriz y vender material eléctrico automotriz: manguera corrugada, conectores eléctricos Delphi, AMP, FCI, UP, JST, cables de batería y lo referente a arneses electricos.
                                        </p><br />
                                        <TabsCategories arnesAutomotriz={arnesAutomotriz} />
                                        <ModalForm />
                                    </Col>
                                    <Col className="ml-auto mr-auto padd" lg="4" md="6">
                                        <Card className="card-coin card-plain">
                                        <CardHeader>
                                    <img
                                        alt="..."
                                        className="img-center img-fluid"
                                        src={av_0_card}
                                    />
                                    <h4 className="title content-center-card">ARNÉS AUTOMOTRIZ</h4>
                                </CardHeader>

                                            <CardBody>
                                                <Nav
                                                    className="nav-tabs-primary justify-content-center"
                                                    tabs
                                                >
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({
                                                                active: this.state.tabs === 1
                                                            })}
                                                            onClick={e => this.toggleTabs(e, "tabs", 1)}

                                                        >
                                                            Carácteristicas
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({
                                                                active: this.state.tabs === 2
                                                            })}
                                                            onClick={e => this.toggleTabs(e, "tabs", 2)}
                                                            href="#"
                                                        >
                                                            Medidas
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>
                                                <TabContent
                                                    className="tab-subcategories"
                                                    activeTab={"tab" + this.state.tabs}
                                                >
                                                    <TabPane tabId="tab2">
                                                        <Table className="tablesorter" responsive>
                                                          <p>Desarrollamos arneses con medidas especiales.</p>
                                                        </Table>
                                                    </TabPane>
                                                    <TabPane tabId="tab1">
                                                    <Table className="tablesorter" responsive>
                                                        <thead>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td>Fácil y rápida instalación.</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Versatilidad de diseño. (Diferentes longuitudes, conectores, terminales, etc.)</td>
                                                        </tr>
                                                        </tbody>
                                                    </Table>
                                                    </TabPane>
                                                </TabContent>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                </div>
                            </div>
                            <div className="section">
                                <Container>
                                    <Row className="justify-content-between">
                                        <Col md="6">
                                            <Row className="justify-content-between align-items-center">
                                                <CarouselCategories items={items}/>
                                            </Row>
                                        </Col>
                                        <Col md="5">
                                            <h1 className="profile-title text-left">Proyectos</h1>
                                            <p className="profile-description text-left">
                                                Fabricamos arnés automotriz como traje a la medida.</p>
                                            <p>Somos capaces de crear un diseño o idea y desarrollarlo en prototipos y desde allí a un producto completamente funcional.</p>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                            <Address />
                        </Container>
                <Footer />
            </div>
        );
    }
}

export default ArnesP3;
