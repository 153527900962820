import React, { Component } from 'react';
import InfiniteCarousel from 'react-leaf-carousel';

const av_1 = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/automotriz%2Fav_1.jpg?alt=media&token=913765c3-fd68-4829-9bff-49a6b2507326";
const av_2 = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/automotriz%2Fav_2.jpg?alt=media&token=7229778d-d368-41c9-87b8-0a67ae70614d";
const av_3 = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/automotriz%2Fav_3.jpg?alt=media&token=f2c274e1-24ba-4a39-be11-24037ed06073";
const av_4 = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/automotriz%2Fav_4.jpg?alt=media&token=0787c82d-e6f1-4c26-8b59-0f0b58c7f732";
const av_5 = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/automotriz%2Fav_5.jpg?alt=media&token=db05ac1c-7a74-4ab4-b916-6d02524e0fd3";
const av_6 = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/automotriz%2Fav_6.jpg?alt=media&token=59d362e7-0fea-4208-bd06-b247a1a0ddc9";
const av_7 = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/automotriz%2Fav_9.jpg?alt=media&token=eb7c6bab-0e5a-4de6-baa3-3f1b2087e9ef";
const av_8 = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/automotriz%2Fav_8.jpg?alt=media&token=9632ece5-a728-4cd7-ba5c-6b651ef676bb";

class Appliances extends Component {
    constructor(props) {
        super(props);
        this.state = {
            appliancesList: [{id:1,source:av_1,alt:"Arnés Autotanque"},
            {id:2,source:av_2,alt:"Arnés Autotanque"},
            {id:3,source:av_3,alt:"Arnés Autotanque"},
            {id:4,source:av_4,alt:"Arnés Autotanque"},
            {id:5,source:av_5,alt:"Arnés Autotanque"},
            {id:6,source:av_6,alt:"Arnés Autotanque"},
            {id:7,source:av_7,alt:"Arnés Autotanque"},
            {id:8,source:av_8,alt:"Arnés Autotanque"}]
        };
    }
    render() {
        return (
            <React.Fragment>
                <div className="section">
                  <div className="subcategory-title">
                        <h1 className="changeColor">ACCESORIOS</h1>
                      <hr className="line-primary hr-appliances hr-resp" />
                    </div>

                    <InfiniteCarousel
                    breakpoints={[
                        {
                            breakpoint: 500,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2,
                            },
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 3,
                            },
                        },
                    ]}
                    dots={false}
                    showSides={true}
                    sidesOpacity={.5}
                    sideSize={.1}
                    slidesToScroll={1}
                    slidesToShow={4}
                    scrollOnDevice={true}
                    lazyLoad={true}
                    swipe={true}
                    responsive={true}
                >
                    {this.state.appliancesList.map((item, index) => (
                            <div key={index}  >
                                <img alt={item.alt} src={item.source}  />
                            </div>
                        ))}
                </InfiniteCarousel>
                </div>
            </React.Fragment>
        );
    }
}
export default Appliances;
