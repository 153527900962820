import React from "react";


import {
    Container,
} from "reactstrap";

class Footer extends React.Component {
    render() {
        return (
            <footer className="footer">
                <Container>
                    <div className="footer-copyright text-center py-3 changeColorCustom">© 2020 Copyright:
                        <span> AB AutoManufacturas.</span>
                    </div>
                </Container>
            </footer>
        );
    }
}

export default Footer;
