import React from 'react';
import {
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col
} from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class Contact extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            fields: {
                name:'',
                email:'',
                phone:'',
                msg:'',
                company:''
            },
            errors: {},
            success: false
        }

    }

    handleValidation(){
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        //Name
        if(!fields["name"]){
            formIsValid = false;
            errors["name"] = "Campo obligatorio.";
        }

        if(typeof fields["name"] !== "undefined"){
            if(!fields["name"].match(/^[a-zA-Z ]+$/)){
                formIsValid = false;
                errors["name"] = "Campo obligatorio.";
            }
        }

        //Email
        if(!fields["email"]){
            formIsValid = false;
            errors["email"] = "Campo obligatorio.";
        }

        if(typeof fields["email"] !== "undefined"){
            let lastAtPos = fields["email"].lastIndexOf('@');
            let lastDotPos = fields["email"].lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') === -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
                formIsValid = false;
                errors["email"] = "No es un email válido.";
            }
        }

        if(!fields["phone"]){
            formIsValid = false;
            errors["phone"] = "Campo obligatorio.";
        }

        if(typeof fields["phone"] !== "undefined"){
            if(!fields["phone"].match(/^[0-9]+$/)){
                formIsValid = false;
                errors["phone"] = "Campo obligatorio.";
            }
        }

        if(!fields["msg"]){
            formIsValid = false;
            errors["msg"] = "Campo obligatorio.";
        }


        this.setState({errors: errors});
        return formIsValid;
    }

    contactSubmit(e){
        e.preventDefault();

        if(this.handleValidation()){
            this.setState({
                fields: {
                    name:'',
                    email:'',
                    phone:'',
                    msg:'',
                    company:''
                },
            });
          this.sendMail();
        }
    }

    sendMail(){
         
        const URL = 'https://us-central1-ab-automanufacturas.cloudfunctions.net/sendMail';
        const dataBody = {
            "name": this.state.fields.name,
            "email": this.state.fields.email,
            "phone": this.state.fields.phone,
            "company": this.state.fields.company,
            "msg": this.state.fields.msg,
        }
        fetch(URL, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            mode:'no-cors',
            body: JSON.stringify(dataBody)

        })
            .then(function (data) {
                // this.setState({
                //     success:true
                // });
                toast.success('Se ha enviado el correo!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            })
            .catch(function (error) {
                console.log('Request failure: ', error);
                toast.error('Ah ocurrido un error, intente de nuevo', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            });
    }

    handleChange(field, e){
        let fields = this.state.fields;
        fields[field] = e.target.value;
        let formIsValidRegex = true;
        let errors = {};
        if(typeof fields["name"] && fields["name"] !== '' && fields["name"].length > 1){
            if(!fields["name"].match(/^[a-zA-Z ]+$/)){
                formIsValidRegex = false;
                errors["name"] = "Solo se aceptan letras.";
            }
        } if(typeof fields["email"] && fields["email"] !== ''){
                let lastAtPos = fields["email"].lastIndexOf('@');
                let lastDotPos = fields["email"].lastIndexOf('.');
                if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') === -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
                    formIsValidRegex = false;
                    errors["email"] = "No es un email válido.";
                }
        }
        if(typeof fields["phone"] && fields["phone"] !== ''){
            if(!fields["phone"].match(/^[0-9]{0,10}$/) || fields["phone"].length < 10){
                formIsValidRegex = false;
                errors["phone"] = "Solo se aceptan números y máximo 10.";
            }
        }

        if(typeof fields["msg"] && fields["msg"] !== ''){
            if(fields["msg"].length > 199){
                formIsValidRegex = false;
                errors["msg"] = "Haz excedido el limite de caracteres.";
            }
        }

        this.setState({fields});
        this.setState({errors: errors});
        return formIsValidRegex;
    }


    render() {
        return (
            <section className="section">
                <Container>
                    {(this.state.success) ?
                        <ToastContainer
                            position="top-right"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnVisibilityChange
                            draggable
                            pauseOnHover
                        />
                        :  <ToastContainer
                            position="top-right"
                            autoClose={4000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnVisibilityChange
                            draggable
                            pauseOnHover
                        />}
                    <Row>
                        <Col md="6">
                            <h1 className="profile-title text-left">Contáctanos</h1>
                                <CardBody>
                                    <Form name="contactform" className="contactform" onSubmit= {this.contactSubmit.bind(this)}>
                                        <Row>
                                            <Col md="6">
                                                <FormGroup>
                                                   <label>Nombre</label>
                                                    <Input type="text" ref="name" size="30" className={this.state.errors["name"] ? "error" : ""} onChange={this.handleChange.bind(this, "name")} value={this.state.fields["name"]}/>
                                                    <span className="error">{this.state.errors["name"]}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label>Tú Email</label>
                                                    <Input
                                                        placeholder="ejemplo@abautoman.com"
                                                        type="email" ref="email" className={this.state.errors["email"] ? "error" : ""} onChange={this.handleChange.bind(this, "email")} value={this.state.fields["email"]}/>
                                                    <span className="error">{this.state.errors["email"]}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label>Tú Teléfono</label>
                                                    <Input placeholder="" ref="phone" type="text" maxLength="10" className={this.state.errors["phone"] ? "error" : ""}  onChange={this.handleChange.bind(this, "phone")} value={this.state.fields["phone"]}/>
                                                    <span className="error">{this.state.errors["phone"]}</span>
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup>
                                                    <label>Compañia</label>
                                                    <Input type="text" ref="company" onChange={this.handleChange.bind(this, "company")} value={this.state.fields["company"]} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <FormGroup>
                                                    <label>Mensaje</label>
                                                    <Input placeholder="Escribenos y con gusto te contactaremos." type="text" ref="msg" size="200" className={this.state.errors["msg"] ? "error" : ""}  onChange={this.handleChange.bind(this, "msg")} value={this.state.fields["msg"]} />
                                                    <span className="error">{this.state.errors["msg"]}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <button  color="info" className="btn-send-email float-right"  id="submit" value="Submit">Envíar</button>
                                    </Form>
                                </CardBody>
                        </Col>
                        <Col className="ml-auto" md="4">
                            <div className="info-horizontal">
                                <div className="icon icon-primary">
                                    <i className="tim-icons icon-square-pin iconColor customColor" />
                                </div>
                                <div className="description">
                                    <h3 className="info-title custom-info">Ubicación</h3>
                                    <p>
                                        Vicente Guerrero #37 San Miguel Chalma, Tlanepantla de Baz, Estado de México.
                                        CP. 54140
                                    </p>
                                </div>
                            </div>
                            <div className="info-horizontal">
                                <div className="icon icon-primary">
                                    <i className="tim-icons icon-single-02 iconColor customColor" />
                                </div>
                                <br></br>
                                <div className="description">
                                    <h3 className="info-title custom-info">Contacto</h3>
                                    <p>
                                        Ing. Abraham Pérez Pérez <br />
                                        Teléfono : <a className="customBlack" href="tel:+55 58 93 92 27" rel="nofollow"> (55) 58 93 92 27 </a><br/>
                                        Celular : <a className="customBlack" href="tel:+55 13 09 56 29" > (55) 13 09 56 29 </a><br/>
                                        Lunes a Viernes, 8:00-18:00
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}
