import React from 'react';
import {
    Card,
    CardBody,
    CardTitle,
    Container,
    Row,
    Col
} from "reactstrap";

import IndexNavbar from "../../../Navbars/IndexNavbar";
import Address from "../../Address/Address";
import Footer from "../../../Footer/Footer";
import Benefits from "../../Benefits/Benefits";
import ModalForm from "../Products/ArnesComponents/ModalForm";
import Map from "../../../Maps/Maps";
import credentials from '../../../credentials';

const imgT = "https://firebasestorage.googleapis.com/v0/b/ab-automanufacturas.appspot.com/o/tanque_card.jpg?alt=media&token=623c0f59-da92-43d1-a42a-d9628e18c569";
const mapUrl = `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${credentials.mapsKey}`;

export default class About extends React.Component {
    render() {
        return (
            <React.Fragment>
                <IndexNavbar />
                <br /><br /><br /><br /><br /><br />
                <section className="section section-lg section-safe">
                    <Container>
                        <Row className="row-grid justify-content-between">
                            <Col md="6">
                                <div className="px-md-5">

                                    <ul className="list-unstyled mt-4">
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div className="icon icon-info mb-2">
                                                    <i className="tim-icons icon-vector custom-size-icon" />
                                                </div>
                                                <div className="ml-3">
                                                    <h3>Productos de excelente calidad.</h3>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div className="icon icon-success mb-2">
                                                    <i className="tim-icons icon-bulb-63 custom-size-icon" />
                                                </div>
                                                <div className="ml-3">
                                                    <h3>Creando soluciones para nuestros clientes.</h3>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div className="icon icon-gold mb-2">
                                                    <i className="tim-icons icon-shape-star custom-size-icon" />
                                                </div>
                                                <div className="ml-3">
                                                    <h3>Servicio excepcional.</h3>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col md="5">
                                <img
                                    alt="..."
                                    className="img-fluid floating"
                                    src={imgT}
                                />
                                <Card className="card-stats bg-danger-cstm">
                                    <CardBody>
                                        <div className="justify-content-center">
                                            <div className="numbers">
                                                <CardTitle tag="p">Clientes</CardTitle>
                                                <p className="card-category text-white"><b>Satisfechos</b></p>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                                <Card className="card-stats bg-info">
                                    <CardBody>
                                        <div className="justify-content-center">
                                            <div className="numbers">
                                                <CardTitle tag="p">Más de 1000</CardTitle>
                                                <p className="card-category text-white">
                                                    <b>Productos</b>
                                                </p>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                                <Card className="card-stats bg-default">
                                    <CardBody>
                                        <div className="justify-content-center">
                                            <div className="numbers">
                                                <CardTitle tag="p">Excelentes</CardTitle>
                                                <p className="card-category text-white"><b>Materiales</b></p>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <div className="section section-nucleo-icons">
                            <Row className="justify-content-center">
                                <Col lg="8" md="12">
                                    <h2 className="title">Misión</h2>
                                    <h4 className="description">
                                          Estamos dedicados a proveer soluciones para la industria que requiere de Arnés Eléctrico,
                                          Diseñando y fabricando productos que cumplen satisfactoriamente sus requisitos.
                                    </h4>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col lg="8" md="12">
                                    <h2 className="title">Visión</h2>
                                    <h4 className="description">
                                        Ser la opción preferida en el Diseño y fabricación de Arnés Eléctrico Automotriz, tanto para los clientes
                                        como para los empleados y proveedores. Ofreciendo productos de calidad superior, un servicio excepcional y asegurando
                                        la sustentabilidad de la empresa; esto con un equipo de trabajo capacitado y auto-dirigido.
                                    </h4>
                                </Col>
                            </Row>
                            <Benefits />
                            <Row className="justify-content-center">
                                <Col lg="10" md="14" sm="4">
                                    <h2 className="title">Ubicación</h2>
                                    <React.Fragment>
                                        <div>
                                        <Map 
                                            googleMapURL = { mapUrl }
                                            containerElement = { <div style={{height: '400px'}} />}
                                            mapElement={<div style={{ height: '100%' }} />} 
                                            loadingElement = {<p>Cargando ...</p>} />
                                        </div>
                                    </React.Fragment>
                                    {/* <div className="btn-wrapper">
                                        <ModalForm />

                                    </div> */}
                                </Col>
                            </Row>
                        </div>
                    </Container>
                    <Address />
                </section>
                <Footer />
            </React.Fragment>

        );
    }
}
