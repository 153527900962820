import React from 'react';
import {
    Container,
    Row,
    Col
} from "reactstrap";

export default class Benefits extends React.Component {
    render() {
        return (
            <div className="section">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg="12">
                            <Row className="row-grid justify-content-center">
                                <Col lg="4">
                                    <div className="info">
                                        <div className="icon icon-primary">
                                            <i className="tim-icons icon-money-coins customColor" />
                                        </div>
                                        <h4 className="info-title">Objetivos</h4>
                                        <hr className="line-primary" />
                                        <p className="justify-contact">
                                        </p>
                                    </div>
                                </Col>
                                <Col lg="4">
                                    <div className="info">
                                        <div className="icon icon-warning">
                                            <i className="tim-icons icon-chart-pie-36 customColor" />
                                        </div>
                                        <h4 className="info-title">Valores</h4>
                                        <hr className="line-warning customColorHR" />
                                        <p className="justify-contact">
                                        </p>
                                    </div>
                                </Col>
                                <Col lg="4">
                                    <div className="info">
                                        <div className="icon icon-success">
                                            <i className="tim-icons icon-single-02 customColor" />
                                        </div>
                                        <h4 className="info-title">Comportamientos</h4>
                                        <hr className="line-success" />
                                        <p className="justify-contact">
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
