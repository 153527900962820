import React from 'react';
import {
    Container,
    Row,
    Col
} from "reactstrap";

export default class Address extends React.Component {
    render() {
        return (
                <Container>
                    <Row className="justify-content-center">
                        <Col lg="12">
                            <Row className="row-grid justify-content-center">

                                <Col lg="4">
                                    <div className="info">
                                        <div className="icon icon-success">
                                            <i className="tim-icons icon-square-pin customColor" />
                                        </div>
                                        <h4 className="info-title">Ubicación</h4>
                                        <hr className="line-success customColor" />
                                        <p className="justify-contact">
                                           Vicente Guerrero #37 San Miguel Chalma, Tlanepantla de Baz, Estado de México.
                                            CP. 54140
                                        </p>
                                    </div>
                                </Col>
                                <Col lg="4">
                                    <div className="info">
                                        <div className="icon icon-success">
                                            <i className="tim-icons icon-email-85 customColor" />
                                        </div>
                                        <h4 className="info-title">Email</h4>
                                        <hr className="line-success customColor" />
                                        <p>
                                            <a href="mailto:aperez@abautoman.com"> aperez@abautoman.com </a>
                                        </p>
                                    </div>
                                </Col>
                                <Col lg="4">
                                    <div className="info">
                                        <div className="icon icon-success">
                                            <i className="tim-icons icon-single-02 customColor" />
                                        </div>
                                        <h4 className="info-title">Contacto</h4>
                                        <hr className="line-success customColor" />
                                        <p>
                                            Ing. Abraham Pérez Pérez <br />
                                            Teléfono : <b><a className="customBlack" href="tel:+55 58 93 92 27" rel="nofollow"> (55) 58 93 92 27 </a></b><br/>
                                            Celular : <b><a className="customBlack" href="tel:+55 13 09 56 29" > (55) 13 09 56 29 </a></b><br/>
                                            Lunes a Viernes, 8:00-18:00
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
        );
    }
}

