import React from 'react';
import { GoogleMap, withScriptjs, withGoogleMap, Marker, InfoWindow } from 'react-google-maps';
import '../Maps/Maps.css';

class Maps extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            activeMarker: {},
            showingInfoWindow: false
        };
    }

    pinSymbol = (color) => {
        return {
            path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
            fillColor: color,
            fillOpacity: 2,
            strokeColor: '#000',
            strokeWeight: 2,
            scale: 1,
       };
    }

    onMarkerClick = () => {
        if(!this.state.showingInfoWindow){
            this.setState({
                showingInfoWindow: true
                  });
        }else{
            this.setState({
                showingInfoWindow: false
                  });
        }
   
    }

  onInfoWindowClose = () => {
    if(!this.state.showingInfoWindow){
        this.setState({
            showingInfoWindow: true
              });
    }else{
        this.setState({
            showingInfoWindow: false
              });
    }
  }

  onMapClicked = () => {
    if (this.state.showingInfoWindow)
      this.setState({
        activeMarker: null,
        showingInfoWindow: false
      });
  };

    render() {
        return (
            <React.Fragment>
        <GoogleMap  defaultZoom={16}
                    defaultCenter={{ lat: 19.5436200, lng:-99.1529100 }} >
            <Marker position={{ lat: 19.5436200,lng: -99.1529100 }} 
                    icon= {this.pinSymbol("#f54336")} 
                    onClick={this.onMarkerClick}  >
           {
               this.state.showingInfoWindow ? 
               <InfoWindow
                onClose={this.onInfoWindowClose}
               >
                    <React.Fragment>
                    <div id="iw-container">
                        <div className="iw-title">AB Automanufacturas S.A. de C.V</div>
                        
                        <div className="iw-subTitle">Contacto</div>
                        <p className="iw-address">Calle Vicente Guerrero #37, Pueblosan Miguel Chalma, 54140 Tlalnepantla de Baz, Méx.<br></br><b>Tel : (55) 58939227</b><br></br>
                      <br></br>Email : <b>aperez@abautoman.com</b><br></br><b>Lunes a Viernes, 8:00-18:00</b></p>                     
                    </div>
                    </React.Fragment>
                    
                </InfoWindow>
                : null
           }
            </Marker>           
        </GoogleMap>
        </React.Fragment>
            )
        }
    }
       
export default withScriptjs (
    withGoogleMap(
        Maps
    )
);

