import React from 'react';

import {
    Nav, NavItem, NavLink, TabContent, TabPane,
} from "reactstrap";
import classnames from "classnames";
import ModalForm from "./ModalForm";

export default class TabsCategories extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            tabs: 1,
            iconTabs: 1,
            textTabs: 4,
       infoTab : [{id:1, type:"arnes-7", title:"Remolque", msg:"Elaboración de arnés electricos para Remolques, con fabricación especial, diseñados y fabricados a la medida del cliente, para cumplir con las necesidades.", tabId:"link1"},
                       {id:2, type:"arnes-7", title:"Autotanque", msg:"Elaboración de arnés para Autotanques, especiales, diseñados y fabricados a la medida del cliente, para cumplir con las necesidades.", tabId: "link2"},
                       {id:3, type:"arnes_autobus",title:"PortaEquipaje", msg:"Elaboración de arnés para PortaEquipaje: Arnés de bocinas, arnés de penumbra, luz de servicio, arnés para multiset, embellecedores, etc.", tabId: "link1"},
                       {id:4, type:"arnes_autobus",title:"Conector USB", msg:"Elaboración de arnés para cargadores USB con y sin toma corriente.", tabId: "link2"},
                       {id:5, type:"arnes-automotriz",title:"Automotriz", msg:"Elaboración de arnés Automotriz, especiales, diseñados y fabricados a medida que cumplan las especificaciones del cliente.", tabId: "link1"}]

        };
    }

    componentWillMount() {
        const arnes1 = this.props.arnes1;
        const arnesAutobus = this.props.arnesAutobus;
        const arnesAutomotriz = this.props.arnesAutomotriz;
        if(arnes1 === 'arnes-type-1'){
            this.setState(state => {
                const newState = state.infoTab.filter(itm => itm.type === 'arnes-7');
                state.infoTab = newState;
                return newState;
            });
        }else if(arnesAutobus === 'arnes_autobus'){
            this.setState(state => {
                const newStateAutobus = state.infoTab.filter(item => item.type === 'arnes_autobus');
                state.infoTab = newStateAutobus;
                return newStateAutobus;
                });
        }else if(arnesAutomotriz === 'arnes_automotriz'){
            this.setState(state => {
                const newStateAutomotriz = state.infoTab.filter(item => item.type === 'arnes-automotriz');
                state.infoTab = newStateAutomotriz;
                return newStateAutomotriz;
            });
        }
    }
    toggleTabs = (e, stateName, index) => {
        e.preventDefault();
        this.setState({
            [stateName]: index
        });
    };

    render() {
        return (
            <React.Fragment>

                <Nav className="nav-tabs-primary" role="tablist" tabs>
                    {this.state.infoTab.map((item, index) => (
                        <NavItem key={item.id}>
                            <NavLink
                                className={classnames({
                                    active: this.state.iconTabs === index+1 })}
                                onClick={e => this.toggleTabs(e, "iconTabs", index+1)}>
                                <i className="tim-icons icon-delivery-fast " />
                                {item.title}
                            </NavLink>

                        </NavItem>
                    ))}
                    {this.state.infoTab.map((item, index) => (
                    <TabContent className="tab-space" activeTab={"link" + this.state.iconTabs} key={item.id} >
                        <TabPane tabId={item.tabId}>
                            <p>
                                {item.msg}
                            </p>
                        </TabPane>
                    </TabContent>
                    ))}
                    <ModalForm />
                </Nav>
            </React.Fragment>
        )
    }
}
